.footer {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 25px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 100;

  .button {
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
    padding: 12px 30px 10px;
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #dddddd;
    text-transform: uppercase;
    transition: all 0.25s ease-in-out;

    &:hover {
      border: 1px solid #333333;
      box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
    }
  }
}
