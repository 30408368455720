.colorCol {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  position: relative;

  &.white {
    color: #FFF;

    .hex:hover {
      background: rgba(255, 255, 255, 0.1);
      cursor: pointer;
    }
  }

  &.black {
    color: #000;

    .hex:hover {
      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

  .hex {
    position: relative;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.03em;
    padding: 10px;
    border-radius: 10px;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.25s;
    z-index: 5;

    span {
      display: block;
      font-size: 12px;
      margin-top: 20px;
      text-transform: capitalize;
      opacity: 0.6;
    }
  }

  .colorName {
    margin-top: 10px;
    text-align: center;
    font-size: 11px;
    opacity: 0.8;
    letter-spacing: 0.05em;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .colorCol {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    .hex {
      font-size: 20px;
      padding: 5px;
     }

    .colorName {
      margin-top: 3px;
    }
  }
}