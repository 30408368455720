.shades {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0px;
  z-index: 5;
  display: grid;
  overflow: hidden;
  transition: height 0.2s ease-in-out;

  &.open {
    height: 100%;
  }

  .shade {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  
    &:hover {
      & > span {
        opacity: 1;
      }
    }
  
    & > span {
      opacity: 0;
    }
  
    &.active {
      & > span {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 768px) {
  .shades {
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 100%;
    width: 0px;
    background-color: #333333;
    transition: width 0.2s ease-in-out;

    &.open {
      width: 100%;
    }
  }

  .shade {
    width: 4%;

    & > span {
      transform: rotate(90deg);
    }
  }
}