.header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 25px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 100;

  .left {
    width: 160px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .right {
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #3d475d;

    .info {
      cursor: pointer;
    }

    .share, .settings {
      cursor: pointer;
      margin-right: 12px;
    }

    .reset {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -1px;
      margin-right: 12px;
      cursor: default;
      user-select: none;
      transition: all 0.2s;

      &.disabled {
        opacity: 0.4;
      }

      &:not(.disabled):hover {
        cursor: pointer;
      }

      &:not(.disabled):active {
        cursor: pointer;
      }
    }

    .undoRedo  {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 12px;
      border-left: 1px solid #ddd;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:first-child {
        margin-right: 10px;
      }

      div.disabled {
        color: #aaa;
        opacity: 0.7;
        cursor: default;
      }
    }

    .icon {
      margin-right: 12px;
      display: flex;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .header .left {
    width: 140px;
  }
}

@media (max-width: 450px) {
  .header {
    padding: 5px 15px;
  }
}