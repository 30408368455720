.toast {
  position: fixed;
  top: 10px;
  right: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.95);
  color: #54e163;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.6);
  z-index: 100;
  animation: slideRight 0.25s ease-in-out;
}