.generateColor {
  position: absolute;
  width: 45px;
  height: 100%;
  max-height: 100%;
  top: 0;
  bottom: 0;
  display: block;

  &.left {
    left: 0;

    .generateColorPlus {
      left: 0;
      transform: translate3d(-21px, -21px, 0) scale(0) rotate(180deg);

      &.first {
        transform: translate3d(5px, -21px, 0) scale(0) rotate(180deg);
      }
    }
  }

  &.right {
    right: 0;

    .generateColorPlus {
      right: 0;
      transform: translate3d(21px, -21px, 0) scale(0) rotate(180deg);

      &.last {
        transform: translate3d(-5px, -21px, 0) scale(0) rotate(180deg);
      }
    }
  }

  .generateColorPlus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    position: absolute;
    border-radius: 50%;
    background-color: #FFFFFF;
    top: 50%;
    color: #333333;
    font-size: 20px;
    cursor: pointer;
    z-index: 99;
    opacity: 0;
    transition: transform 0.1s, opacity 0.1s;
  
    &::after {
      content: '';
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #FFFFFF;
      position: absolute;
      z-index: 1;
      transition: all 0.2s ease-in-out;
    }
  
    &:hover {
      &::after {
        background-color: #EDEDEE;
      }
    }
  
    & > i {
      z-index: 2;
    }
  }

  &:hover {
    &.left {
      .generateColorPlus {
        opacity: 1;
        transform: translate3d(-21px, -21px, 0) scale(1) rotate(180deg);

        &.first {
          transform: translate3d(5px, -21px, 0) scale(1) rotate(180deg);
        }
      }
    }

    &.right {
      .generateColorPlus {
        opacity: 1;
        transform: translate3d(21px, -21px, 0) scale(1) rotate(180deg);

        &.last {
          transform: translate3d(-5px, -21px, 0) scale(1) rotate(180deg);
        }
      }
    }
  }
}