.palette {
  display: flex;
  height: calc(100vh - 60px);
  overflow-x: hidden;
  position: relative;
}

@media (max-width: 768px) {
  .palette {
    flex-direction: column;
    height: calc(100vh - 120px);
  }
}