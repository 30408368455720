.colorColActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;

  &.white {
    color: #FFF;

    & > div:hover {
      background: rgba(255, 255, 255, 0.1);
      cursor: pointer;
    }
  }

  &.black {
    color: #000;

    & > div:hover {
      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    font-size: 18px;
    background: transparent;
    transition: all 0.25s;
    color: inherit;

    & > i {
      color: inherit;
    }
  }
}

@media (max-width: 768px) {
  .colorColActions {
    flex-direction: row;

    .generatePallete {
      transform: rotate(90deg);
    }
  }
}