* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
}

@keyframes slideRight {
  from {
    right: -1000px;
  }
  to {
    right: 20px;
  }
}