.modalWrapper {
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: rgb(255, 255, 255);
  overflow: auto;
  outline: none;
  z-index: 1001;
  border-radius: 12px;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    .title {
      font-size: 18px;
      text-align: center;
      letter-spacing: 0.7px;
      color: #555;
    }

    .close {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      margin-top: 1px;
      position: absolute;
      right: 15px;
      cursor: pointer;
      opacity: 1;
      transition: all 0.2s ease-in-out;
      border-radius: 50%;
    
      &:hover {
        background-color: rgba($color:  #3d475d, $alpha: 0.3);
      }
    }
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 400px) {
  .modalWrapper {
    .header {
      .title {
        font-size: 16px;
      }
    }
  }
}
